const CROSSCHECK_RISK_FLAGS = {
  diverseIdDocCountries: 'Identity documents were issued in different countries.',
  manyAccountDuplicates: 'Lots of account duplicates are detected (>=3 accounts from one source; one client and one source key).',
  accountsInManyServices: 'The same account is registered in different services (>=5 accounts in different sources).',
  addressCountryVsIpCountryMismatch: 'The physical address does not meet the IP address.',
  idDocCountryVsIpCountryMismatch: 'ID document country mismatches the country IP address.',
  exifCountryVsIdDocCountryOrIpCountryMismatch: 'Country of photo creation is different from IP and ID document countries.',
};
const EMAIL_RISK_FLAGS = {
  mediumRisk: 'A verdict made by the system according to the check results and internal logic.',
  highRisk: 'A verdict made by the system according to the check results and internal logic.',
  disposable: 'Temporary email address that usually expires after a short period of time.',
  noWebRegistrations: 'Email address registration is not detected.',
  noWebsiteExists: 'Email domain does not exist.',
  nonDeliverable: 'An email address to which the messages cannot be delivered.',
};

const PHONE_RISK_FLAGS = {
  mediumRisk: 'A verdict made by the system according to the check results and internal logic.',
  highRisk: 'A verdict made by the system according to the check results and internal logic.',
  virtual: 'A virtual phone number is a number not associated with any physical location, allowing to make calls via internet.',
  disposable: 'A temporary phone number that usually expires after a short period of time.',
  noWebRegistrations: 'Phone number registration is not detected.',
};

const DEVICE_RISK_FLAGS = {
  vpnUsage: 'Detects whether VPN connection is used.',
  multipleDevices: 'Informs whether the applicant uses multiple devices (>1 device).',
  multipleMobileDevices: 'Informs whether the applicant uses multiple mobile devices (>1 mobile platform).',
  lengthySession: 'The session lasts too long (>=10 minutes).',
  failedSessionContinuation: 'The session was interrupted.',
  distantIpLocations: 'Login from different and distant IP addresses for a short period of time.',
};

const SELFIE_RISK_FLAGS = {
  forced: 'The person is coerced into verification.',
  asleep: 'The person in the picture is asleep.',
  multipleFaces: 'There are multiple faces present in the photo.',
  estimatedAgeMismatch: 'The estimated age does not match the age indicated in documents.',
  virtualCameraPresent: 'A visual software simulating real camera is detected.',
  manyAttempts: 'There were too many attempts of passing a selfie check (>5 selfie attempts).',
  sameFaceWithDifferentData: 'Supposedly different data in documents with the same faces detected.',
};

const AML_RISK_FLAGS = {
  PEP: 'Applicant belongs to the PEP (Politically Exposed Person) category.',
  sanctions: 'Applicant was found in sanctions lists.',
  terrorism: 'Applicant is suspected of terrorism.',
  crime: 'Applicant is suspected of criminal activities.',
  adverseMedia: 'Compromising information related to the applicant was found in the media.',
};

const PERSON_RISK_FLAGS = {
  famousPerson: 'Indicates an assumption that the applicant is a famous person.',
  strangeName: 'Applicant name seems to be not a real one.',
  noPhoneNamesFromWebServices: 'No phone names found in web services.',
  phoneNameMismatchFromWebServices: 'The provided applicant name does not match the names found by the specified phone number.',
  noEmailNamesFromWebServices: 'No email names found in web services.',
  emailNameMismatchFromWebServices: 'The provided applicant name does not match the names found by the specified email address.',
};
export default {
  CROSSCHECK_RISK_FLAGS,
  EMAIL_RISK_FLAGS,
  PHONE_RISK_FLAGS,
  DEVICE_RISK_FLAGS,
  SELFIE_RISK_FLAGS,
  AML_RISK_FLAGS,
  PERSON_RISK_FLAGS,
};
