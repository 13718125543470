import { Card, Table, TableBody, TableCell, TableRow, Typography, Dialog, Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay, StatusLabel, TraxxButton, TraxxInput } from 'app/components';
import { resultParser, useAsyncTask, berbixFlags } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import TitleField from '../TitleField';
import { useSnackbar } from 'notistack';
import { API } from 'app/services';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
  updateButton: {
    float: 'right',
    height: 25,
    fontSize: 12,
  },
  verificationConfirmationBox: {
    maxHeight: 500,
    width: 500,
    padding: 10,
  },
  dialogHeader: {
    textAlign: 'center',
    padding: 10,
  },
  calculate: {
    margin: 'auto',
  },
  updateVerificationDataRow: {
    width: 250,
  },
  boldText: {
    fontWeight: "bold",
  },
}));

const SummarisedTable = (props) => {
  const classes = useStyles();
  const { facematch, liveness = {}, document = {}, isBerbix, isSumsub, verificationId, overriddenStatus, className } = props;
  const { Result, action, flags, images } = document;
  const { LivenessResult, Error } = liveness;
  const [openVerificationUpdate, setOpenVerificationUpdate] = useState(false);
  const [inputs, setInputs] = useState({
    status: '',
    reason: '',
  });
  const [status, setStatus] = useState(action);
  const [reason, setReason] = useState(action);
  const { enqueueSnackbar } = useSnackbar();
  const [runVerificationUpdate] = useAsyncTask('updateVerificationStatus');
  const [selfieFlagged, setSelfieFlagged] = useState(false);
  const [livenessFlagged, setLivenessFlagged] = useState(false);

  useEffect(() => {
    checkOverrideStatus();
    checkFlags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value });
  };

  const getLabelStyle = (result) => {
    if (isBerbix) {
      // console.log("result", result);
      switch (result) {
        case 'accept':
          return 'success';
        case 'reject':
          return 'error';
        case 'review':
          return 'review';
        default:
          return 'unknown';
      }
    } else {
      switch (result) {
        case 1:
          return 'success';
        case 2:
          return 'error';
        default:
          return 'warning';
      }
    }
  };

  const openVerificationUpdateBox  = () => {
    setOpenVerificationUpdate(true);
  };

  const sendVerificationUpdateClose = () => {
    setOpenVerificationUpdate(false);
  };

  const onSubmitUpdateVerification = () => {
    // console.log(inputs.status, inputs.reason);
    if(!inputs.status || !inputs.reason) { enqueueSnackbar("Please enter status/reason", {
      variant: 'error',
      autoHideDuration: 3000,
    }) } else {
      runVerificationUpdate(async () => {
      await API.Users.verification_status_update(verificationId, { status: inputs.status, reason: inputs.reason})
      enqueueSnackbar("Verification Updated Successfully", {
        variant: 'success',
        autoHideDuration: 3000,
      })
      window.location.reload(false);
      });
    }
  };

  const checkOverrideStatus = () => {
    if(overriddenStatus) { 
      // console.log("overriddenStatus", overriddenStatus);
      const overrideDescription = JSON.parse(overriddenStatus?.extras);
      // console.log("overrideDescription", overrideDescription);
      setStatus(overrideDescription.status);
      setReason(overrideDescription.reason);
      // console.log("action", overrideDescription.status);
    }
  };

  const checkFlags = () => {
    for(const flag in flags) {
      if(berbixFlags.SELFIE_FLAGS.includes(flag)) setSelfieFlagged(true);
      if(berbixFlags.LIVENESS_FLAGS.includes(flag)) setLivenessFlagged(true); 
    }
  }

  // console.log('[doc] summary document: ', document);

  // console.log('overridenStatus: ', overriddenStatus);

  // Sumsub
  if (isSumsub) {
    const { action, info, type } = document;
    return <Card className={clsx(classes.card, className)}>

      {/* TODO: test overide status & debug */}
      <TitleField>Summary  {action === 'reject' && !overriddenStatus && (<TraxxButton className={classes.updateButton} onSubmit={openVerificationUpdateBox}> Update Verification Status </TraxxButton>)}</TitleField>

      {Object.keys(document).length > 0 && <Table className={classes.table}>
        <TableBody>
          {overriddenStatus 
            ? <DataRow name="Verification Status" value={<StatusLabel variant={status === "accept" ? "success" : "error"}>{ status }</StatusLabel>} />
            : <DataRow name="Verification Status" value={<StatusLabel variant={action === "accept" ? "success" : "error"}>{ action }</StatusLabel>} />
          }
          { info?.status?.IDENTITY && <DataRow name="Document Verification" value={<StatusLabel variant={info?.status?.IDENTITY?.reviewResult?.reviewAnswer === "GREEN" ? "success" : "error"}>{ info?.status?.IDENTITY?.reviewResult?.reviewAnswer === "GREEN" ? "accept" : "reject" }</StatusLabel>} /> }
          { info?.status?.SELFIE && <DataRow name="Selfie Assessment" value={<StatusLabel variant={info?.status?.SELFIE?.reviewResult?.reviewAnswer === "GREEN" ? "success" : "error"}>{ info?.status?.SELFIE?.reviewResult?.reviewAnswer === "GREEN" ? "accept" : "reject" }</StatusLabel>} /> }
          
          { overriddenStatus && <>
            <DataRow name="Verification Status Updated By:" value={`${overriddenStatus.actor.person.firstname ? overriddenStatus.actor.person.firstname : ""}  ${overriddenStatus.actor.person.lastname  ? overriddenStatus.actor.person.lastname : ""}`} />
            <DataRow name="Updated on:" value={<DateDisplay date={overriddenStatus.timestamp} />} />
            <DataRow name="Verification Status Update Reason:" value={reason} />
          </> }

          {/* <DataRow name="Selfie Assessment" value={selfieFlagged ? <StatusLabel variant={"error"} > Failed </StatusLabel> : <StatusLabel variant={"success"} > Passed </StatusLabel>} />
          <DataRow name="Liveness Assessment" value={livenessFlagged ? <StatusLabel variant={"error"} > Failed </StatusLabel> : <StatusLabel variant={"success"} > Passed </StatusLabel>} />
          <DataRow name="Liveness Assessment" value={<StatusLabel variant={LivenessResult?.LivenessAssessment === 'Live' ? 'success' : 'warning'}>{LivenessResult?.LivenessAssessment}</StatusLabel>} />
          <DataRow name="Liveness Assessment" value={<StatusLabel variant="error">Error</StatusLabel>} />
          <TableRow>
            <TableCell colSpan="2">{Error}</TableCell>
          </TableRow>
          <DataRow name="Face Match" value={<Typography className={classes.sameline}>{facematch.IsMatch ? <StatusLabel variant="success">Match</StatusLabel> : <StatusLabel variant="error">Does not match</StatusLabel>}</Typography>} /> */}

        </TableBody>
      </Table>}

      {openVerificationUpdate && (
        <Dialog maxWidth={'md'} open={openVerificationUpdate} onClose={sendVerificationUpdateClose}>
        <Box className={classes.verificationConfirmationBox}>
          <Typography variant="h6" className={classes.dialogHeader}>
            Update Document Verification Status
          </Typography>
          <hr />
            <DataRow name={"New Verification Status"} value={<Select className={classes.updateVerificationDataRow} onChange={handleChange('status')} value={inputs.status}> 
              <MenuItem value="accept">Accept</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
            </Select> } />
            <TableRow>
              <TableCell className={classes.boldText}>Reason for update</TableCell>
              <TableCell><TraxxInput id="reason" className={classes.updateVerificationDataRow} placeholder={"Expired ID, Under 21, etc."} value={inputs.reason} autoComplete="off" onChange={handleChange('reason')} /></TableCell>
            </TableRow>
            <hr />
            <TraxxButton className={classes.updateButton} onSubmit={onSubmitUpdateVerification}>Update</TraxxButton>
        </Box>
      </Dialog>
      )}

    </Card>
  }

  // Acuant & Berbix
  else {
  return (
   <Card className={clsx(classes.card, className)}>
      <TitleField>Summary {isBerbix && (action !== 'accept') && !overriddenStatus && (<TraxxButton className={classes.updateButton} onSubmit={openVerificationUpdateBox}> Update Verification Status </TraxxButton>)}</TitleField>
      <Table className={classes.table}>
        <TableBody>
          <DataRow name="Document Verification" value={<StatusLabel variant={getLabelStyle(isBerbix ? status : parseInt(Result))}>{ isBerbix ? resultParser.BerbixResultParser(status) : resultParser.AlertResultParser(parseInt(Result))}</StatusLabel>} />
          {overriddenStatus && (<>
            <DataRow name="Verification Status Updated By:" value={`${overriddenStatus.actor.person.firstname ? overriddenStatus.actor.person.firstname : ""}  ${overriddenStatus.actor.person.lastname  ? overriddenStatus.actor.person.lastname : ""}`} />
            <DataRow name="Updated on:" value={<DateDisplay date={overriddenStatus.timestamp} />} />
            <DataRow name="Verification Status Update Reason:" value={reason} />
          </>)}
          {isBerbix && images?.liveness?.full_image && (<DataRow name="Selfie Assessment" value={selfieFlagged ? <StatusLabel variant={"error"} > Failed </StatusLabel> : <StatusLabel variant={"success"} > Passed </StatusLabel>} />)}
          {isBerbix && images?.liveness?.full_image && (<DataRow name="Liveness Assessment" value={livenessFlagged ? <StatusLabel variant={"error"} > Failed </StatusLabel> : <StatusLabel variant={"success"} > Passed </StatusLabel>} />)}
          {(LivenessResult?.LivenessAssessment || Error) && (
            <>
              {LivenessResult?.LivenessAssessment && <DataRow name="Liveness Assessment" value={<StatusLabel variant={LivenessResult?.LivenessAssessment === 'Live' ? 'success' : 'warning'}>{LivenessResult?.LivenessAssessment}</StatusLabel>} />}
              {Error && (
                <>
                  <DataRow name="Liveness Assessment" value={<StatusLabel variant="error">Error</StatusLabel>} />
                  <TableRow>
                    <TableCell colSpan="2">{Error}</TableCell>
                  </TableRow>
                </>
              )}
            </>
          )}
          {facematch && <DataRow name="Face Match" value={<Typography className={classes.sameline}>{facematch.IsMatch ? <StatusLabel variant="success">Match</StatusLabel> : <StatusLabel variant="error">Does not match</StatusLabel>}</Typography>} />}
        </TableBody>
      </Table>
      {openVerificationUpdate && (
        <Dialog maxWidth={'md'} open={openVerificationUpdate} onClose={sendVerificationUpdateClose}>
        <Box className={classes.verificationConfirmationBox}>
          <Typography variant="h6" className={classes.dialogHeader}>
            Update Document Verification Status
          </Typography>
          <hr />
            <DataRow name={"New Verification Status"} value={<Select className={classes.updateVerificationDataRow} onChange={handleChange('status')} value={inputs.status}> 
              <MenuItem value="accept">Accept</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
            </Select> } />
            <TableRow>
              <TableCell className={classes.boldText}>Reason for update</TableCell>
              <TableCell><TraxxInput id="reason" className={classes.updateVerificationDataRow} placeholder={"Expired ID, Under 21, etc."} value={inputs.reason} onChange={handleChange('reason')} /></TableCell>
            </TableRow>
            <hr />
            <TraxxButton className={classes.updateButton} onSubmit={onSubmitUpdateVerification}>Update</TraxxButton>
        </Box>
      </Dialog>
      )}
    </Card>
    );
  };
}

export default SummarisedTable;
