import { Box, Card, Grid, LinearProgress, Link, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AcuantBrand, BerbixBrand, SumsubBrand, Crumbs, DataRow, DateDisplay, MonoText, StatusLabel, Title, TraxxButton } from 'app/components';
import { API } from 'app/services';
import { useRouter, useToastCatcher } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import {
  AlertsTable,
  BerbixFieldTable,
  BerbixImageField,
  BiographicTable,
  ClassificationTable,
  DataFieldImages,
  DataFieldTable,
  FaceMatchTable,
  FlagsTable,
  ImageField,
  LivenessTable,
  SummarisedResult,
  SumsubFieldTable,
  SumsubRiskTable,
  VerificationDetailsTable,
} from './components';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    borderRadius: 5,
  },
  head: {
    fontWeight: 'bold',
  },
  walletCard: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  underline: {
    textDecoration: 'underline',
  },
  topSpace: {
    marginTop: theme.spacing(2),
  },
  bottomField: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  noFlex: {
    flexDirection: 'row',
  },
}));

let mounted = false;
function DocumentDetailComp(props) {
  const { routeType = 'account', documentResult, onNext, className } = props;
  const classes = useStyles();
  const [document, setDocument] = useState(documentResult);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'getDocument' });
  const [rawData, setRawData] = useState();
  const [liveness, setLiveness] = useState();
  const [facematch, setFacematch] = useState();
  const [berbixData, setBerbixData] = useState();
  const [sumsubData, setSumsubData] = useState();
  const [isBerbix, setIsBerbix] = useState(false);
  const [isSumsub, setIsSumsub] = useState(false);
  const [verificationId, setVerificationId] = useState();
  const [overriddenStatus, setOverriddenStatus] = useState();

  const [imageError, setImageError] = useState(false);

  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const doc_id = params.document_id || documentResult.model.id;
  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getDocumentDetail();
    // eslint-disable-next-line
  }, [access]);

  const getDocumentDetail = () => {
    if (!doc_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.get_document : API.Admin.get_verify_detail;
      const { model } = await loader(doc_id);
      if (mounted) {
        setDocument(model);
        const { berbix_refresh_token } = model;
        if (berbix_refresh_token) {
          if (berbix_refresh_token === 'sumsub') setIsSumsub(true);
          else setIsBerbix(true);
        }

        if (model.verification_results) {
          model.verification_results.forEach((request) => {
            const { type } = request;
            console.log(type);
            if (request.verification_result_raw) {
              const data = JSON.parse(request.verification_result_raw?.content);

              console.log(data);

              // if sumsub
              if (berbix_refresh_token === 'sumsub') {
                console.log('[doc] data: ', data);
                setSumsubData(data);
                setVerificationId(model.id);
                setOverriddenStatus(model.event_log);
              } else {
                if (type === 'facematch') setFacematch(data);
                if (type === 'liveness') setLiveness(data);
                if (type === 'id-card' || type === 'passport') setRawData(data);
                if (type === 'berbix') {
                  setBerbixData(data);
                  // console.log(data);
                  setVerificationId(request.verification_id);
                  // console.log(model.event_log);
                  setOverriddenStatus(model.event_log);
                }
              }
            }
          });
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
    });
  };

  const updateVerify = () => {
    errorCatcher(async () => {
      await API.Users.verify_update(verificationId);
      window.location.reload(false);
    });
  };

  const downloadImages = () => {
    errorCatcher(async () => {
      const images = await API.Screening.download_images(berbixData.images);
      const zip = new JSZip();
      const uuid = berbixData.customer_uid.slice(5);
      Object.keys(images).forEach((key) => {
        const image = images[key].full_image;
        if (image || image !== undefined) {
          const name = uuid ? `${key}_${uuid}.jpeg` : `${key}.jpeg`;
          zip.file(name, image, { base64: true });
        }
      });
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const name = uuid ? `traxxid_${uuid}.zip` : `traxxid_img.zip`;
        saveAs(content, name);
      });
    });
  };

  // TODO document status to be added into statusMap
  const getButtons = () => {
    if (routeType !== 'account' || documentResult) return;
    let arr = [
      // { space: document ? 3 : 8, holder: true },
      { space: 4, onClick: () => router.history.push(`/document-verify`), text: 'Verify new document' },
    ];
    if (document)
      arr.push({
        space: 4,
        onClick: () => router.history.push(`/screenings/create?case=${document.case?.reference}&document=${params.document_id}`),
        text: 'Use document for screening',
      });
    return arr;
  };

  // console.log('sumsubData: ', sumsubData);

  const { Classification, Images, DataFields, Alerts, Biographic } = rawData || {};

  return (
    <Box className={clsx(classes.root, className)}>
      <Crumbs current="Detail" links={[{ text: 'Documents', href: routeType === 'admin' ? '/admin/documents' : '/documents' }]} />
      <Title prefix="Document detail" floatButtons={getButtons()}>
        {isSumsub ? <SumsubBrand /> : isBerbix ? <BerbixBrand /> : <AcuantBrand />}
      </Title>
      {documentResult && (
        <Box mb={1} display="flex">
          {/* {onBack && (
            <Button onClick={onBack}>Previous</Button>
          )} */}
          <Box flexGrow={1} />
          <TraxxButton onSubmit={onNext}>Next</TraxxButton>
        </Box>
      )}
      <Grid container spacing={2}>
        {loading['getDocument'] && (
          <Grid item g={12} sm={12}>
            <LinearProgress size={30} />
          </Grid>
        )}
        {!loading['getDocument'] && document && (
          <>
            <Grid item lg={6} sm={12}>
              {isSumsub && <SummarisedResult verificationId={verificationId} overriddenStatus={overriddenStatus} isSumsub={isSumsub} isBerbix={isBerbix} document={sumsubData} />}
              {!isSumsub && isBerbix && <SummarisedResult verificationId={verificationId} overriddenStatus={overriddenStatus} isBerbix={isBerbix} document={berbixData} />}
              {!isSumsub && !isBerbix && (liveness || facematch || rawData) && <SummarisedResult facematch={facematch} liveness={liveness} document={rawData} />}
              <Card className={classes.topSpace}>
                <TableContainer className={classes.table} component={Paper}>
                  <Table>
                    <TableBody>
                      <DataRow name="Document ID" value={<MonoText>{document.reference}</MonoText>} />
                      <DataRow name="Type" value={<MonoText>{document.type}</MonoText>} />
                      <DataRow name="Status" value={<StatusLabel variant={document.status === 'complete' ? 'success' : 'warning'}>{document.status}</StatusLabel>} />
                      <DataRow name="Created date" value={<DateDisplay date={document.created_at} />} />
                      <DataRow
                        name="Case ID"
                        wrapperProps={{ underline: 'always', to: `/screenings/cases/${document.case?.id}/detail`, component: BrowserLink }}
                        wrapper={Link}
                        value={<MonoText className={classes.underline}>{document.case?.reference}</MonoText>}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
              {Images && <ImageField className={classes.topSpace} images={Images} />}
              {Biographic && <BiographicTable className={classes.topSpace} bio={Biographic} />}
              {liveness && <LivenessTable className={classes.topSpace} liveness={liveness} />}
              {Classification && <ClassificationTable className={classes.topSpace} classification={Classification} />}
              {facematch && <FaceMatchTable className={classes.topSpace} facematch={facematch} />}
              {DataFields && <DataFieldImages className={classes.topSpace} datafield={DataFields} />}

              {isBerbix && berbixData?.images && (
                <BerbixImageField
                  updateVerify={updateVerify}
                  downloadImages={downloadImages}
                  className={classes.topSpace}
                  images={berbixData.images}
                  flags={berbixData.flags}
                  imageError={imageError}
                  setImageError={setImageError}
                  routeType={routeType}
                />
              )}
              {!isBerbix && isSumsub && sumsubData && <VerificationDetailsTable className={sumsubData.action === 'reject' && classes.topSpace} datafield={sumsubData.info?.applicant?.review} />}
            </Grid>

            <Grid item spacing={2} lg={6} sm={12}>
              <Box className={classes.noFlex}>
                {/* Acuant */}
                {!isBerbix && !isSumsub && Alerts && <AlertsTable alerts={Alerts} />}
                {!isBerbix && !isSumsub && DataFields && <DataFieldTable className={classes.topSpace} datafield={DataFields} />}

                {/* Berbix */}
                {isBerbix && berbixData?.flags && <FlagsTable flags={berbixData.flags} />}
                {isBerbix && berbixData?.fields && <BerbixFieldTable className={classes.topSpace} datafield={berbixData.fields} />}

                {/* Sumsub */}
                {isSumsub && sumsubData && sumsubData.action === 'reject' && <FlagsTable flags={sumsubData.info?.applicant?.review?.reviewResult?.rejectLabels} />}
                {!isBerbix && isSumsub && sumsubData && <SumsubFieldTable className={sumsubData.action === 'reject' && classes.topSpace} datafield={sumsubData.info?.applicant?.info} />}
                {isSumsub && sumsubData && sumsubData.action === 'reject' && <SumsubRiskTable flags={sumsubData.info?.applicant?.riskLabels?.crossCheck} />}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      {documentResult && (
        <Box mt={6} display="flex">
          {/* {onBack && (
            <Button onClick={onBack}>Previous</Button>
          )} */}
          <Box flexGrow={1} />
          <TraxxButton onSubmit={onNext}>Next</TraxxButton>
        </Box>
      )}
    </Box>
  );
}

export default DocumentDetailComp;
