import { Box, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatusLabel } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';
import { sumsubFlags } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: 'white',
  },
  result: {
    marginRight: theme.spacing(2),
  },
  body: {
    textAlign: 'center',
  },
}));

const FlagsTable = (props) => {
  const { flags = [], className } = props;
  const classes = useStyles();

  const getLabelStyle = (result) => {
    switch (result) {
      case 1:
        return 'success';
      case 2:
        return 'error';
      default:
        return 'warning';
    }
  };

  const refactorFlag = (flag) => {
    return flag.replaceAll('_', ' ');
  };

  const getTooltip = (flag) => {
    return sumsubFlags.CROSSCHECK_RISK_FLAGS[flag];
  };

  return (
    <Box className={clsx(className, classes.card)}>
      <TitleField>Sumsub Risk Labels</TitleField>
      <Table className={classes.table}>
        <TableBody className={classes.body}>
          {flags.map((flag) =>
            flag !== 'id_duplicate_detected' && flag !== 'id_duplicate_possible' ? (
              <StatusLabel tooltip={getTooltip(flag)} variant={getLabelStyle(flag)} className={classes.result}>
                {refactorFlag(flag)}
              </StatusLabel>
            ) : (
              <></>
            )
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default FlagsTable;
